import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { catchError, finalize, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoggerService } from './logging/logger.service';
import { JobType, Kit, KitSearch } from '../insurgo-interfaces/kit';
import { Tape } from '../insurgo-interfaces/tape';
import { ErrorHandlingService } from './error-handling.service';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import { manifestReconcileResult } from '../insurgo-interfaces/manifest-reconcile-result';

// whether the search wants to include <x>
export interface jobSelectionOptions {
  refurbishmentJobs: boolean,
  systemTests: boolean,
  scanToStock: boolean,
  auditJobs: boolean,
  completeJobs: boolean,
  scanAllJobs: boolean
}


@Injectable()
export class KitService {

  apiUrl = environment.apiUrl;

  constructor(
    public authHttp: HttpClient,
    private readonly logger: LoggerService,
    private errorService: ErrorHandlingService
  ) { }

  /**
   * Returns the last entered KIT number, this is for the next kit id generator
   * 
   * ***Doesn't really work if multiple people are creating Kits at same time***
   */
  getLastKitNumber(): Observable<Kit> {
    const URL = `${this.apiUrl}/kit/kit/5/x`;

    return this.authHttp.get<Kit[]>(URL)
      .pipe(
        map(response => {
          // mysql returns as an array, this route will only have one response (limit 1 in query)
          return response[0];
        }),
        catchError(this.handleError('getLastKitNumber', URL))
      );
  }

  /**
   * Returns array of Kits which are in the same 'status' category
   * @param statusID 
   */
  getKitsByStatusID(statusID: number): Observable<Kit[]> {
    const URL = `${this.apiUrl}/kit/kit/2/${statusID}`;

    return this.authHttp.get<Kit[]>(URL)
      .pipe(
        catchError(this.handleError('getKitsByStatusID', URL))
      );
  }

  /**
   * Gets KIT Numbers to show as available on Asset Track
   * Also includes Scan to Stock order types
   */
  //getKits(companyID: number, inclS2S: boolean, inclST: boolean, onlyActive: boolean): Observable<Kit[]> {
  getKits(companyID: number, selectionOptions: jobSelectionOptions): Observable<Kit[]> {

    const URL = `${this.apiUrl}/kit/kit/1/${companyID}`;
    const _onlyActive = !selectionOptions.completeJobs;
    const params = {
      inclS2S: selectionOptions.scanToStock.toString(),
      inclST: selectionOptions.systemTests.toString(),
      onlyActive: _onlyActive.toString(),
      inclAudit: selectionOptions.auditJobs.toString(),
      inclRefurb: selectionOptions.refurbishmentJobs.toString(),
      inclScanAll: selectionOptions.scanAllJobs.toString(),
    };
    return this.authHttp.get<Kit[]>(URL, { params })
      .pipe(
        catchError(this.handleError('getKits', URL))
      );
  }

  getKitModelBreakdown(kitID: number): Observable<any[]> {
    const URL = `${this.apiUrl}/kit/kit/8/${kitID}`;

    return this.authHttp.get<any[]>(URL)
      .pipe(
        catchError(this.handleError('getKitModelBreakdown', URL))
      );
  }

  async getFullKitDetails(kitID: number): Promise<Kit> {
      const modelBreakdown = await this.getTapesOnKitByModel([kitID]).toPromise();
      const kitOverview = await this.getKitReportOverview(kitID).toPromise();

      kitOverview.address.addressString = `${kitOverview.address.line1 + ','} ${(kitOverview.address.line2 || '') === '' ? '' : kitOverview.address.line2 + ','} ${kitOverview.address.city + ','} ${kitOverview.address.postalCode}`;

      kitOverview.dateCreatedHR = moment(kitOverview.dateCreated).format('DD/MM/YYYY');
      if (kitOverview.dateCompleted) {
        kitOverview.dateCompletedHR = moment(kitOverview.dateCompleted).format('DD/MM/YYYY');
      } else {
        kitOverview.dateCompletedHR = 'Not Yet Completed';
      }
      const _elapsed: number = new Date().getTime() - kitOverview.dateCreated;
      kitOverview.daysInProgress = (_elapsed / (1000 * 60 * 60 * 24));

      // this.foundKit = kitOverview;

      kitOverview.tapeBreakdown = modelBreakdown;
      //set initial numbers
      kitOverview.totalUsedTapes = 0;
      kitOverview.totalNewTapes = 0;
      kitOverview.totalWIPTapes = 0;
      kitOverview.totalATStatus = 0;
      kitOverview.totalDEStatus = 0;
      kitOverview.totalERStatus = 0;
      kitOverview.totalISStatus = 0;
      kitOverview.totalSDStatus = 0;
      kitOverview.totalSSStatus = 0;
      kitOverview.totalSOStatus = 0;

      kitOverview.totalAuditedTapes = 0;
      kitOverview.totalAnalysedTapes = 0;


      kitOverview.tapeBreakdown.forEach(model => {

        kitOverview.totalUsedTapes += model.totalUsedTapes;
        kitOverview.totalNewTapes += model.totalNewTapes;

        kitOverview.totalATStatus += model.totalATStatus;
        kitOverview.totalDEStatus += model.totalDEStatus;
        kitOverview.totalERStatus += model.totalERStatus;
        kitOverview.totalISStatus += model.totalISStatus;
        kitOverview.totalSDStatus += model.totalSDStatus;
        kitOverview.totalSSStatus += model.totalSSStatus;
        kitOverview.totalSOStatus += model.totalSOStatus;

        kitOverview.totalAuditedTapes += model.totalAUStatus;
        kitOverview.totalAnalysedTapes += model.totalANStatus;


      });
      if (kitOverview.orderTypeID === JobType.STANDARD) {
        kitOverview.totalWIPTapes = kitOverview.totalATStatus + kitOverview.totalERStatus
      } else if (kitOverview.orderTypeID === JobType.SEND_ALL_TAPES) {
        kitOverview.totalWIPTapes = kitOverview.totalATStatus + kitOverview.totalERStatus + kitOverview.totalDEStatus;
      } else if (kitOverview.orderTypeID === JobType.TAPE_AUDIT) {
        kitOverview.totalWIPTapes = kitOverview.totalAuditedTapes;
      }

      return kitOverview;   

  }
  updateKit(kitID: number, addressID: number, companyID: number
    , kitStatusID: number
    , collectionID: string
    , dateCreated: number
    , dateReceived: number
    , dateCompleted: number,
    kitNumber: string): Observable<Kit[]> {
    const URL = `${this.apiUrl}/kit/kit/${kitID}`;
    const updatingKit = {
      addressID: addressID,
      companyID: companyID,
      kitStatusID: kitStatusID,
      collectionID: collectionID,
      dateCreated: dateCreated,
      dateReceived: dateReceived,
      dateCompleted: dateCompleted,
      kitNumber: kitNumber
    }

    return this.authHttp.put(URL, updatingKit)
      .pipe(
        catchError(this.handleError('updateKit', URL))
      );
  }

  syncKitToHub(kitNumber: string): Observable<void> {
    const URL = `${this.apiUrl}/process/syncJob`;
    const body = {
      jobNumber: kitNumber
    }
    return this.authHttp.post(URL, body)
      .pipe(
        catchError(this.handleError('syncKitToHub', URL))
      );
  }

  saveNewKit(jobNumber: string, addressID: number, companyID: number, clientJobNumber: string, jobTypeID: number, manifest?: File): Observable<number> {
    const URL = `${this.apiUrl}/job`;

    const formData = new FormData();
    // Append all form values to formData   
    formData.append('addressID', addressID.toString());
    formData.append('companyID', companyID.toString());
    formData.append('jobTypeID', jobTypeID.toString());
    formData.append('clientJobNumber', clientJobNumber);
    formData.append('jobNumber', jobNumber);

    if (manifest) formData.append('manifestFile', manifest);

    return this.authHttp.post<number>(URL, formData).pipe(
      catchError((error) => {
        return this.errorService.handleErrorTrustedRoute(error);
      }),
    );
  }

  getKitReportOverview(kitID: number): Observable<Kit> {
    const URL = `${this.apiUrl}/kit/kit/7/${kitID}`;

    return this.authHttp.get<Kit[]>(URL)
      .pipe(
        map(response => {
          // mysql returns as an array, this route will only have one response (limit 1 in query)
          return response[0];
        }),
        catchError(this.handleError('getKitReportOverview', URL))
      );
  }
  
  getTapesOnKitByManufacturer(kitID: number): Observable<any> {
    // let databaseString = ''
    // for (let i = 0; i < kitID.length; i++) {
    //   const element = kitID[i];
    //   databaseString += `${element};`
    // }
    const URL = `${this.apiUrl}/kit/kit/8/${kitID}`;

    return this.authHttp.get<any[]>(URL)
      .pipe(
        catchError(this.handleError('getTapesOnKitByManufacturer', URL))
      );
  }

  getTapesOnKitByModel(kitID: number[]): Observable<any> {
    let databaseString = ''
    for (let i = 0; i < kitID.length; i++) {
      const element = kitID[i];
      databaseString += `${element};`
    }
    const URL = `${this.apiUrl}/kit/kit/9/${databaseString}`;

    return this.authHttp.get<any[]>(URL)
      .pipe(
        catchError(this.handleError('getTapesOnKitByModel', URL))
      );
  }

  getTapesOnKit(kitID: number[]): Observable<any> {
    let databaseString = ''
    for (let i = 0; i < kitID.length; i++) {
      const element = kitID[i];
      databaseString += `${element};`
    }
    const URL = `${this.apiUrl}/reports/summary/tapesOnKits/${databaseString}`;

    return this.authHttp.get<any[]>(URL)
      .pipe(
        catchError(this.handleError('getTapesOnKitByModel', URL))
      );
  }

  getKitsCreatedBetweenDates(startSearchMS: number, endSearchMS: number): Observable<KitSearch[]> {
    const URL = `${this.apiUrl}/reports/summary/kitByMonth/${startSearchMS}/${endSearchMS}`;

    return this.authHttp.get<KitSearch[]>(URL)
      .pipe(
        catchError(this.handleError('getKitsCreatedBetweenDates', URL))
      );
  }

  updateKitDestructionCosts(kitID: number, degaussCost: number, kitCost: number, swatCost: number): Observable<any> {
    const URL = `${this.apiUrl}/kit/admin/`;

    return this.authHttp.post(URL, { kitID: kitID, degaussingCost: degaussCost, kitCost: kitCost, swatCost: swatCost })
      .pipe(
        catchError(this.handleError('updateKitDestructionCosts', URL))
      );
  }

  updateKitExtraInfo(kitID: number, data: any): Observable<any> {
    const URL = `${this.apiUrl}/kit/admin/kitInfo`;

    return this.authHttp.patch(URL, { kitID: kitID, updateData: data })
      .pipe(
        catchError(this.handleError('updateKitExtraInfo', URL))
      );
  }
  getChargeableTapes(kitID: number): Observable<any> {
    const URL = `${this.apiUrl}/kit/kit/10/${kitID}`;

    return this.authHttp.get(URL)
      .pipe(
        catchError(this.handleError('getChargeableTapes', URL))
      );
  }

  getBasicKitInfo(kitID: number): Observable<Kit> {
    const URL = `${this.apiUrl}/kit/kit/12/${kitID}`;

    return this.authHttp.get<Kit>(URL)
      .pipe(
        catchError(this.handleError('getBasicKitInfo', URL))
      );
  }

  updateKitJobType(kitID: number, newTypeID: JobType): Observable<any> {
    const URL = `${this.apiUrl}/kit/admin/job-type`;

    return this.authHttp.patch(URL, { jobID: kitID, newJobType: newTypeID })
      .pipe(
        catchError(this.handleError('updateKitJobType', URL))
      );
  }
  
  downloadManifestFile(manifestID: number): Observable<any> {
    const URL = `${this.apiUrl}/manifest/${manifestID}`;
    return this.authHttp.get(URL, { responseType: 'blob', observe: 'response' }).pipe(
      map((response: any) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition.split(';')[1].split('=')[1].replace(/"/g, ''); // Extract the filename from the Content-Disposition header
        const blob = new Blob([response.body], { type: response.body.type });
  
        FileSaver.saveAs(blob, filename);
      }),
      catchError(this.handleError('downloadManifestFile', URL))
    );
  }
  
  clearTapesOnKit(kitID: number): Observable<any> {
    const URL = `${this.apiUrl}/kit/admin/clearTapes/${kitID}`;

    return this.authHttp.delete(URL)
      .pipe(
        catchError(this.handleError('clearTapesOnKit', URL))
      );
  }

  private handleError(operation: String, url: String): any {
    return (err: any) => {
      const errMsg = `error in ${operation}() retrieving ${url}`;
      this.logger.error(`${errMsg}:`, err);
      if (err instanceof HttpErrorResponse) {
        // you could extract more info about the error if you want, e.g.:
        // console.log(`status: ${err.status}, ${err.statusText}`);
      }
      return ErrorObservable.create(errMsg);
    };
  }
}
